import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiTestService } from 'src/app/services/api-test.service';

@Component({
  selector: 'app-api-test',
  templateUrl: './api-test.component.html',
  styleUrls: ['./api-test.component.css']
})
export class ApiTestComponent implements OnInit {
  cols: Array<{ [key: string]: any }> = [{
  }]
  heading = 'Csv Parser';
  icon = 'pe-7s-plane icon-gradient bg-tempting-azure';
  constructor(private SpinnerService: NgxSpinnerService, private apiTestService: ApiTestService) { }

  ngOnInit(): void {
    this.cols = [];
  }
  GetNewEmail() {
    this.cols = [];
    this.SpinnerService.show();
    this.apiTestService.GetNewEmail()
      .subscribe(
        response => {
          this.SpinnerService.hide();
          var arr = JSON.parse(response);
          this.UpdateNewEmailAPI(arr[0], 'click');
        },
        error => {
          this.SpinnerService.hide();
        });
  }
  UpdateNewEmailAPI(email,type) {
    const data = {
      type: type,
      email: email
    };
    var d = 'http://www.livauto.com/?key=d0bbe992-b166-40de-894a-4673420510eb&email=[email]&type=[type]&name=refi';
    d = d.replace("[email]", email);
    d = d.replace("[type]", type);

    this.apiTestService.UpdateNewEmail(data)
      .subscribe(
        response => {
          var res = '';
          if (response.res == 200) {
            res = '1';
          }
          else {
            res = '0';
          }

          this.cols.push
            ({
              url: d,
              res: res,
            });

          if (type == 'click') {
            this.UpdateNewEmailAPI(email, 'open');
          }
          if (type == 'open') {
            this.UpdateNewEmailAPI(email, 'bounce');
          }
          if (type == 'bounce') {
            this.UpdateNewEmailAPI(email, 'complain');
          }
          if (type == 'complain') {
            this.UpdateNewEmailAPI(email, 'unsubscribe');
          }

        },
        error => {
          this.cols.push
            ({
              url: d,
              res: '0',
            });

          if (type == 'click') {
            this.UpdateNewEmailAPI(email, 'open');
          }
          if (type == 'open') {
            this.UpdateNewEmailAPI(email, 'bounce');
          }
          if (type == 'bounce') {
            this.UpdateNewEmailAPI(email, 'complain');
          }
          if (type == 'complain') {
            this.UpdateNewEmailAPI(email, 'unsubscribe');
          }
        });
  }

}
