import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

// import {SearchComponent} from './DemoPages/consultant/search/search.component';

import {BaseLayoutComponent} from './Layout/base-layout/base-layout.component';
import {PagesLayoutComponent} from './Layout/pages-layout/pages-layout.component';



import {StandardComponent} from './DemoPages/Elements/Buttons/standard/standard.component';
import {DropdownsComponent} from './DemoPages/Elements/dropdowns/dropdowns.component';
import {CardsComponent} from './DemoPages/Elements/cards/cards.component';
import {ListGroupsComponent} from './DemoPages/Elements/list-groups/list-groups.component';
import {TimelineComponent} from './DemoPages/Elements/timeline/timeline.component';
import {IconsComponent} from './DemoPages/Elements/icons/icons.component';
import {AccordionsComponent} from './DemoPages/Components/accordions/accordions.component';
import {CarouselComponent} from './DemoPages/Components/carousel/carousel.component';
import {ModalsComponent} from './DemoPages/Components/modals/modals.component';
import {ProgressBarComponent} from './DemoPages/Components/progress-bar/progress-bar.component';
import {PaginationComponent} from './DemoPages/Components/pagination/pagination.component';
import {TooltipsPopoversComponent} from './DemoPages/Components/tooltips-popovers/tooltips-popovers.component';
import {ChartBoxes3Component} from './DemoPages/Widgets/chart-boxes3/chart-boxes3.component';
import {ControlsComponent} from './DemoPages/Forms/Elements/controls/controls.component';
import {LayoutComponent} from './DemoPages/Forms/Elements/layout/layout.component';
import { LoginComponent } from './DemoPages/UserPages/login/login.component';
import { DashboardComponent } from './DemoPages/Dashboards/dashboard/dashboard.component';
import { LoginActivate } from './DemoPages/UserPages/login/login-activate.model';
import { AllStatsComponent } from './DemoPages/all-stats/all-stats.component';
import { DomainCsvFilesComponent } from './DemoPages/domain-csv-files/domain-csv-files.component';
import { ApiTestComponent } from './DemoPages/api-test/api-test.component';
const routes: Routes = [
  {
    path: '',
    component: BaseLayoutComponent,
    children: [
      // Dashboads
     { path: '', component: DashboardComponent, data: { extraParameter: 'dashboardsMenu' }, canActivate: [LoginActivate] },
      { path: 'csv-parser', component: DashboardComponent, data: { extraParameter: 'dashboardsMenu' }, canActivate: [LoginActivate] },
      { path: 'all-stats', component: AllStatsComponent, data: { extraParameter: 'dashboardsMenu' }, canActivate: [LoginActivate] },

      //{ path: 'data-filter-custom', component: DataFilterColumnsComponent, data: { extraParameter: 'dashboardsMenu' }, canActivate: [LoginActivate] },
      //{ path: 'domains-filter-custom', component: DomainsFiltersCustomComponent, data: { extraParameter: 'dashboardsMenu' }, canActivate: [LoginActivate] },
      //{ path: 'logs', component: LogsComponent, data: { extraParameter: 'dashboardsMenu' }, canActivate: [LoginActivate] },
      //{ path: 'suppressions', component: SuppressionsComponent, data: { extraParameter: 'dashboardsMenu' }, canActivate: [LoginActivate] },
      { path: 'domain-csv-files', component: DomainCsvFilesComponent, data: { extraParameter: 'dashboardsMenu' }, canActivate: [LoginActivate] },
      { path: 'api-test', component: ApiTestComponent, data: { extraParameter: 'dashboardsMenu' }, canActivate: [LoginActivate] },

      
      //{ path: 'show-openers', component: ShowOpenersComponent, data: { extraParameter: 'dashboardsMenu' }, canActivate: [LoginActivate] },
      //{ path: 'add-openers', component: AddOpenersComponent, data: { extraParameter: 'dashboardsMenu' }, canActivate: [LoginActivate] },
      //{ path: 'openers/export-csv', component: ExportOpenersComponent, data: { extraParameter: 'dashboardsMenu' }, canActivate: [LoginActivate] },

      //{ path: 'show-clickers', component: ShowClickersComponent, data: { extraParameter: 'dashboardsMenu' }, canActivate: [LoginActivate] },
      //{ path: 'add-clickers', component: AddClickersComponent, data: { extraParameter: 'dashboardsMenu' }, canActivate: [LoginActivate] },
      //{ path: 'clickers/export-csv', component: ExportClickersComponent, data: { extraParameter: 'dashboardsMenu' }, canActivate: [LoginActivate] },

      //  { path: 'combine-tables', component: CombineTablesComponent, data: { extraParameter: 'dashboardsMenu' },canActivate:[LoginActivate] },
      {path: 'elements/buttons-standard', component: StandardComponent, data: {extraParameter: 'elementsMenu'}},
      {path: 'elements/dropdowns', component: DropdownsComponent, data: {extraParameter: 'elementsMenu'}},
      {path: 'elements/icons', component: IconsComponent, data: {extraParameter: 'elementsMenu'}},
      {path: 'elements/cards', component: CardsComponent, data: {extraParameter: 'elementsMenu'}},
      {path: 'elements/list-group', component: ListGroupsComponent, data: {extraParameter: 'elementsMenu'}},
      {path: 'elements/timeline', component: TimelineComponent, data: {extraParameter: 'elementsMenu'}},

      // Components

      // {path: 'components/tabs', component: TabsComponent, data: {extraParameter: 'componentsMenu'}},
      {path: 'components/accordions', component: AccordionsComponent, data: {extraParameter: 'componentsMenu'}},
      {path: 'components/modals', component: ModalsComponent, data: {extraParameter: 'componentsMenu'}},
      {path: 'components/progress-bar', component: ProgressBarComponent, data: {extraParameter: 'componentsMenu'}},
      {path: 'components/tooltips-popovers', component: TooltipsPopoversComponent, data: {extraParameter: 'componentsMenu'}},
      {path: 'components/carousel', component: CarouselComponent, data: {extraParameter: 'componentsMenu'}},
      {path: 'components/pagination', component: PaginationComponent, data: {extraParameter: 'componentsMenu'}},

      // Tables


      // Widgets

      {path: 'widgets/chart-boxes-3', component: ChartBoxes3Component, data: {extraParameter: 'pagesMenu3'}},

      // Forms Elements

      {path: 'forms/controls', component: ControlsComponent, data: {extraParameter: 'formElementsMenu'}},
      {path: 'forms/layouts', component: LayoutComponent, data: {extraParameter: 'formElementsMenu'}},

    ]

  },
  { path: 'sign-in', component: LoginComponent, data: { extraParameter: '' } },
  { path: '**', redirectTo: 'sign-in' },
  

    
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
    useHash: true,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
