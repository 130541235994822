import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { UserService } from 'src/app/services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { CombineTables, CsvService } from 'src/app/services/csv.service';
import { interval, Subscription } from 'rxjs';
import { CsvUpdateService } from 'src/app/services/csv-update.service';
import * as moment from 'moment';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  filename = '';
  mappedData: CombineTables[] = [];
  maintable_columns: any;
  disabled: boolean = true;
  file: any;
  processed=0;
  vendor ='RefinanceCopy';
  total=0;
  success=0;
  failed=0;
  failedtxt='';
  bshowfailed=false;
  statid:any;
  mySub: Subscription;
  addressCol=0;
  cols: string[] = [];
  checkdomains: any;
  faPlus = faPlus;
  totalRec = 0;
  heading = 'Csv Parser';
  icon = 'pe-7s-plane icon-gradient bg-tempting-azure';
  constructor(public _csvService: CsvService, private csvUpdateService: CsvUpdateService,private confirmationDialogService: ConfirmationDialogService, private userService: UserService, private http: HttpClient, private SpinnerService: NgxSpinnerService, private formBuilder: FormBuilder, private httpClient: HttpClient, private router: Router, private toastr: ToastrService) {
  }

  ngOnInit() {
    this.GetTableColumns();
  }
  DomainsFilter() {
    this.router.navigate(['/domains-filter-custom']);
  }
  public getItem() {
    return 'admin';
  }
 
  public async importDataFromCSV(event: any) {
    let fileContent = await this.getTextFromFile(event);
    this._csvService.importDataFromCSV(fileContent);
    this.cols = this._csvService.propertyNames;

    

  }
  private async getTextFromFile(event: any) {
    const file: File = event.target.files[0];
    this.file = file;
    this.filename = this.file.name;
    let fileContent = await file.text();
    return fileContent;
  }
  

  UploadCsv() {
   
    if ( this.filename == '' || this.filename == null
      || this.filename == undefined
    ) {

    }
    else {
      var mappedData = this.mappedData;
      this._csvService.dataStr = this._csvService.dataStr.filter(item => item != '' && item != '\r' );
      this._csvService.propertyNames = this._csvService.propertyNames.filter(item => item != 'Vendor' && item != '' && item != '\r' );
      var length = this._csvService.dataStr.length;
      this.total=length;
      var v = this.vendor.replace(" ", "_"); 
      const data = {
        cols: this._csvService.propertyNames,
        filename: "tbl_" + v,
        total:length,
        vendor:this.vendor,
        mappedData: mappedData
      };

      this.csvUpdateService.CreateTable(data)
        .subscribe(
          response => {
            this.SpinnerService.show();
            if (response.res == '1') {
              this.statid=response.statid;
              this.mySub = interval(1000).subscribe((func => {
                this.getstats();
              }))

              this.PostCsvData(response.table,response.statid);
            }
          },
          error => {
          });
    }
  }
  stopInterval() {
    this.mySub.unsubscribe()
}
GetTableColumns()
{
  const data = {
    table: 'admin_febdata.dbo.tblRefinance',
  };
  this.csvUpdateService.GetTableColumns(data)
    .subscribe(
      response => {
        this.mappedData=[];
        this.maintable_columns = response;
        this.maintable_columns.forEach((row) => {
          var selectedValue = '';
          //if (row.name == 'createdat') {
          //  selectedValue = 'Lead Date';
          //}
          //if (row.name == 'leaddate') {
          //  selectedValue = 'Lead Date';
          //}
          //if (row.name == 'firstname') {
          //  selectedValue = 'First Name';
          //}
          //if (row.name == 'lastname') {
          //  selectedValue = 'Last Name';
          //}
          //if (row.name == 'city') {
          //  selectedValue = 'City';
          //}
          //if (row.name == 'state') {
          //  selectedValue = 'State';
          //}
          //if (row.name == 'zip') {
          //  selectedValue = 'Zip';
          //}

          //if (row.name == 'streetaddress') {
          //  selectedValue = 'Street Address';
          //}
          
          //if (row.name == 'homephone') {
          //  selectedValue = 'Home Phone';
          //}
          //if (row.name == 'housetype') {
          //  selectedValue = 'House Type';
          //}
          //if (row.name == 'housevalue') {
          //  selectedValue = 'House Value';
          //}
          //if (row.name == 'loanamount') {
          //  selectedValue = 'Loan Amount';
          //}
          //if (row.name == 'cashout') {
          //  selectedValue = 'Cash Out Value';
          //}
          //if (row.name == 'fhaloan') {
          //  selectedValue = 'FHA Loan';
          //}
          //if (row.name == 'creditstatus') {
          //  selectedValue = 'Credit Status';
          //}
          //if (row.name == 'military') {
          //  selectedValue = 'Military ?';
          //}
          //if (row.name == 'email') {
          //  selectedValue = 'Email';
          //}
          //if (row.name == 'uploadtype') {
          //  selectedValue = 'Upload Type';
          //}
          //if (row.name == 'jornayaleadidtoken') {
          //  selectedValue = 'Jornaya LeadiD Token';
          //}
          
          //if (row.name == 'ipaddress') {
          //  selectedValue = 'IP Address';
          //}
          //if (row.name == 'timesaved') {
          //  selectedValue = 'Timesaved';
          //}
          //if (row.name == 'trustedformurl') {
          //  selectedValue = 'Trusted Form URL';
          //}
          

          this.mappedData.push(
            {
              column:row.name,
              selectedValue: selectedValue
            }
          );

        })
      },
      error => {
      });
}


  ShowFailedData()
  {
    this.bshowfailed=true;
    console.log(this.failedtxt);
  }
  SplitByComma(str)
  {
    var arr2=[];
      var arr = str.split(',');
      for (let i = 0; i < arr.length; i++) {
        let isValidDate = Date.parse(arr[i]);
        var valid=false;
        if(arr[i].includes('/') || arr[i].includes(':'))
        {
          valid=true;
        }
        if (isNaN(isValidDate) || valid == false) {
          arr2.push(arr[i]);
        }
        else{
          var date = moment(new Date(isValidDate)).format('MM/DD/YYYY HH:mm:ss');
          arr2.push(date);
        }
      }
    return arr2;
  }
  HeaderName(propertyNames: string[])
  {
    var h = propertyNames.filter(item => item != 'vendor');
    return h;
  }
  getstats()
  {
    const data = {
      id:this.statid
    };
    this.csvUpdateService.getstats(data)
      .subscribe(
        response => {

          this.processed=response[0].process;
          this.success=response[0].success;
          this.failed=response[0].failed;
          if(this.processed > 0 && this.processed ==this.total)
          {
            this.SpinnerService.hide();
            this.stopInterval();
          }
        },
        error => {
          console.log(error);
        });
  }
  PostCsvData(tbl,statid) {
    if(this.addressCol == null || this.addressCol == undefined)
    {
      this.addressCol=0;
    }
    const userid = localStorage.getItem("token");
    const data = {
      recs: this._csvService.dataStr,
      totalcols:this._csvService.propertyNames.length,
      tbl: tbl,
      statid:statid,
      addresscol:this.addressCol,
      vendor: this.vendor,
      userid: userid,
      filename:this.filename
    };
    this.csvUpdateService.InsertCsvData(data)
      .subscribe(
        response => {
        },
        error => {
          console.log(error);
        });
  }

}
