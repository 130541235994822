import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AllstatService } from 'src/app/services/allstat.service';

@Component({
  selector: 'app-all-stats',
  templateUrl: './all-stats.component.html',
  styleUrls: ['./all-stats.component.css']
})
export class AllStatsComponent implements OnInit {
  heading = 'All Stats';
  icon = 'pe-7s-plane icon-gradient bg-tempting-azure';
  stats: any;
  constructor(private SpinnerService: NgxSpinnerService,private allstatService: AllstatService) { }
  ngOnInit(): void {
    this.GetAllStats();
  }
  GetAllStats() {
    this.SpinnerService.show();
    this.allstatService.getAllStats()
      .subscribe(
        response => {
          this.SpinnerService.hide();
          console.log(response);
          this.stats = response;
        },
        error => {
          this.SpinnerService.hide();
        });
  }
}
